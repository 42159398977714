<template>
  <div>
    <Row :gutter="10">
      <Col span="2">
        <Input v-model="searchKey.name" placeholder="名称" clearable/>
      </Col>
      <Col span="2">
        <Select v-model="searchKey.type" style="width: 100%" placeholder="活动类型">
          <Option value="">全部</Option>
          <Option v-for="item in activity_type" :value="item.id" :key="item.id" >{{ item.name }}</Option>
        </Select>
      </Col>
      <Col span="2">
        <Select v-model="searchKey.zhaomu" style="width: 100%" placeholder="是否募集">
          <Option value="">全部</Option>
          <Option value="1">是</Option>
          <Option value="0">否</Option>
        </Select>
      </Col>
      <Col span="2">
        <Button type="primary" @click="search()">查询</Button>
      </Col>
    </Row>
    <Row style="margin-top: 15px">
      <Col span="24">
        <Table stripe border :columns="columns" :data="list">
          <template slot="no" slot-scope="{index}">{{index + 1}}</template>
          <template slot="need_zhaomu" slot-scope="{row}">
            <span v-if="row.activity_need_zhaomu === 1">是</span>
            <span v-else>否</span>
          </template>
          <template slot="state" slot-scope="{row}">
            <span v-if="row.activity_state===0">待审核</span>
            <span v-else-if="row.activity_state===10">待发布</span>
            <span v-else-if="row.activity_state===20">招募中</span>
            <span v-else-if="row.activity_state===30">报名中</span>
            <span v-else-if="row.activity_state===40">进行中</span>
            <span v-else-if="row.activity_state===50">已结束</span>
          </template>
          <template slot="check" slot-scope="{row,index}">
            <Checkbox v-model="row.activity_check" @on-change="onCheckChange(index, $event)"></Checkbox>
          </template>
          <template slot-scope="{row,index}" slot="rec">
            <Checkbox v-model="row.activity_rec" :true-value="1" :false-value="0" @on-change="onRecChange(index, $event)"></Checkbox>
          </template>
          <template slot="action" slot-scope="{row}">
            <Button size="small" type="primary" @click="applyList(row.activity_id)" style="margin-right: 5px">报名列表</Button>
            <Button size="small" type="primary" @click="fundingExport(row.activity_id)" v-if="row.activity_need_zhaomu === 1 && row.activity_funding !== 0" style="margin-right: 5px">
              募资列表
            </Button>
            <Button size="small" type="primary" @click="managerExport(row.activity_id)"  v-if="row.activity_need_zhaomu === 1 && row.activity_manager !== 0" style="margin-right: 5px">
              管理员列表
            </Button>
            <Button size="small" type="primary" @click="detail(row.activity_id)">
              详情
            </Button>
            <Button size="small" type="error" style="margin-left: 5px" @click="del(row.activity_id)">删除</Button>
          </template>
        </Table>
      </Col>
    </Row>

    <Row style="margin-top: 15px">
      <Col span="24" style="text-align: center">
        <Page show-sizer show-elevator show-total :total="total" :current="searchKey.page" :page-size="searchKey.pagesize" @on-change="pageChange" @on-page-size-change="pageSizeChange"/>
      </Col>
    </Row>

    <Modal v-model="modal.agree" @on-ok="agree()">
      <p>确认要审核通过这一项吗？</p>
    </Modal>

    <Modal v-model="modal.disagree" @on-ok="disagree()">
      <Input maxlength="20" show-word-limit v-model="reason" type="textarea" placeholder="请填写不通过原因" />
    </Modal>
  </div>
</template>

<script>
import api from "../../api/xiaochengxu";
export default {
  name: "Activity",
  data: function(){
    return {
      activity_type: [
        {
          id: 1,
          name: "享玩"
        },
        {
          id: 2,
          name: "公益"
        },
        {
          id: 3,
          name: "晓学"
        }
      ],
      searchKey: {
        name: '',
        type: '',
        zhaomu: '',
        page: 1,
        pagesize: 30
      },
      total: 0,
      columns: [
        {title: '#', slot: 'no', width: 80, align: 'center'},
        {title: '名称', key: 'activity_name', width: 250},
        {title: '分类', key: 'activity_type_name', width: 80},
        {title: '报名截止', key: 'activity_apply_end_time', width: 200},
        {title: '开始时间', key: 'activity_begin_time', width: 150},
        {title: '结束时间', key: 'activity_end_time', width: 150},
        {title: '是否募集', slot: 'need_zhaomu', width: 100,align: 'center'},
        {title: '当前状态', slot: 'state', width: 100,align: 'center'},
        {title: '审核', slot: 'check', width: 80,align: 'center'},
        {title: '推荐', slot: 'rec', width: 80,align: 'center'},
        {title: '操作', slot: 'action', align: 'center'}
      ],
      list: [],
      modal: {
        agree: false,
        disagree: false
      },
      activity_index: '',
      reason: '',
    }
  },
  methods:{
    del: function(id){
      this.$Modal.confirm({
        content: '<p>确定要删除这一项吗？</p>',
        onOk: () => {
          this.$http.delete(api.activityDel, {data: {id:id}}).then(response => {
            if(response.data.success) {
              this.getList();
              this.$Message.success('删除成功')
            } else {
              this.$Message.error(response.data.err);
              console.log(response)
            }
          })
        }
      });
    },
    applyList: function(id){
      this.$router.push({path: '/xiaochengxu/activity/apply', query: {id:id}})
    },
    onRecChange: function(index, e){
      this.$http.put(api.activityRec, {
        activity_id: this.list[index].activity_id,
        rec: e
      }).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功')
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    pageSizeChange: function(size){
      this.searchKey.pagesize = size;
      this.getList();
    },
    pageChange: function(p){
      this.searchKey.page = p;
      this.getList();
    },
    getList: function(){
      this.$http.get(api.activityList, {params: this.searchKey}).then(response => {
        if(response.data.success) {
          this.list = response.data.res;
          this.total = response.data.count;
          this.list.forEach((item, index) => {
            this.list[index].activity_check = item.activity_state >= 10;
            for(let i of this.activity_type) {
              if(i.id === item.activity_type) {
                this.list[index].activity_type_name = i.name;
                break;
              }
            }
          })
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    onCheckChange: function(index, e){
      this.activity_index = index;
      if(e) {
        this.modal.agree = true;
      } else {
        this.modal.disagree = true;
      }

      // this.$http.put(api.activityCheck, {
      //   activity_id: this.list[index].activity_id,
      //   check: e ? 1 : 0,
      //   user_id: this.list[index].activity_initiator
      // }).then(response => {
      //   if(response.data.success) {
      //     this.$Message.success('修改成功')
      //     this.list[index].activity_check = e;
      //   } else {
      //     this.$Message.error(response.data.err);
      //     console.log(response)
      //   }
      // })
    },
    disagree: function(){
      this.$http.put(api.activityCheck, {
        activity_id: this.list[this.activity_index].activity_id,
        check: 0,
        user_id: this.list[this.activity_index].activity_initiator,
        reason: this.reason
      }).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功')
          this.list[this.activity_index].activity_check = false;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    agree: function(){
      this.$http.put(api.activityCheck, {
        activity_id: this.list[this.activity_index].activity_id,
        check: 1,
        user_id: this.list[this.activity_index].activity_initiator
      }).then(response => {
        if(response.data.success) {
          this.$Message.success('修改成功')
          this.list[this.activity_index].activity_check = true;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    search: function(){
      this.searchKey.page = 1;
      this.getList()
    },
    detail: function(id){
      console.log(id)
      this.$router.push({path: '/xiaochengxu/activity/detail', query: {id:id}})
    },
    // applyExport: function(id){
    //   this.$http.get(api.activityApplyExport, {params: {activity_id: id}}).then(response => {
    //     if(response.data.success) {
    //       window.location.href = response.data.path;
    //     } else {
    //       this.$Message.error(response.data.err);
    //       console.log(response)
    //     }
    //   })
    // },
    fundingExport: function(id){
      this.$http.get(api.activityFundingExport, {params: {activity_id: id}}).then(response => {
        if(response.data.success) {
          window.location.href = response.data.path;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    },
    managerExport: function(id){
      this.$http.get(api.activityManagerExport, {params: {activity_id: id}}).then(response => {
        if(response.data.success) {
          window.location.href = response.data.path;
        } else {
          this.$Message.error(response.data.err);
          console.log(response)
        }
      })
    }
  },
  mounted() {
    this.getList();
  }
}
</script>

<style scoped>

</style>